import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../../_shared/services/ui.service';
import { ApiService } from '../../_shared/services/api.service';
import { AuthService } from '../../_shared/services/auth.service';
import { AppPermission } from '../../_shared/models/user';
import { ZIXI_LOCATIONS } from '../../_shared/models/constants';

@Component({
  selector: 'app-station-detail',
  templateUrl: './station-detail.component.html',
  styleUrls: ['./station-detail.component.scss']
})
export class StationDetailComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private uiService: UiService,
    private apiService: ApiService,
    private authService: AuthService) { }

  stationId: number;
  station: any;
  options: any;
  canEdit: boolean;
  zixi: any;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.stationId = parseInt(params['id']);

      if (Number.isNaN(this.stationId)) {
        this.handleNotFound();
        return;
      }

      this.canEdit = this.authService.isPermitted(AppPermission.mngStations);

      this.getOptions();
    });
  }

  refresh(fromUi = false) {
    if (fromUi) {
      this.station = null; // clear out to visually show a refresh
    }

    this.getStation();
  }

  save() {
    this.apiService.post({
      method: this.uiService.isSysAdmin() ? "station/sysadmin" : "station/stationAdmin",
      data: this.station,
      onSuccess: (r) => {
        if (this.stationId === 0) {
          this.uiService.toast.success("Station created");
          this.router.navigate(["/stations", r.result.stationId]);
        } else {
          this.uiService.toast.success("Station updated");
          this.refresh();
        }
      }
    });
  }

  navigateToStations() {
    this.router.navigate(["/stations"]);
  }

  getStation() {
    if (this.stationId === 0) {
      this.station = {};
    } else {
      this.apiService.get({
        method: `station/${this.stationId}`,
        onSuccess: (r) => {
          this.station = r.result;
          this.getExtraStationInfo();
        }
      });
    }
  }

  getOptions() {
    this.options = {};

    this.apiService.get({
      method: "lookup/platforms",
      onSuccess: (r) => { this.options.platforms = r.result; }
    });

    this.apiService.get({
      method: "lookup/dma",
      onSuccess: (r) => { this.options.dmas = r.result; }
    });

    this.apiService.get({
      method: "lookup/users",
      onSuccess: (r) => { this.options.users = r.result; }
    });

    this.apiService.get({
      method: "lookup/owners",
      onSuccess: (r) => { this.options.owners = r.result; }
    });

    this.apiService.get({
      method: "lookup/operators",
      onSuccess: (r) => { this.options.operators = r.result; }
    });

    this.apiService.get({
      method: "lookup/scheduleSource",
      onSuccess: (r) => { this.options.scheduleSources = r.result; }
    });

    this.apiService.get({
      method: "lookup/harvesterStation",
      params: { includeInactive: true },
      onSuccess: (r) => {
        this.options.stations = r.result;

        // TODO: fix RXJS issue so this can be done properly
        this.refresh();
      }
    });

    this.options.broadcasters = {};

    ZIXI_LOCATIONS.forEach(location => {
      this.apiService.get({
        method: `zixi/broadcasters/${location}`,
        onSuccess: (r) => {
          this.options.broadcasters[location] = r.result;
        }
      });
    });
  }

  getExtraStationInfo() {
    var s = this.options.stations.find((x: any) => x.stationDefaultDataId === this.station.stationDefaultDataId);
    this.station._timeZone = s?.ianaTimeZone;
    this.station._city = s ? `${s.city}, ${s.state}` : null;
  }

  handleNotFound() {
    this.uiService.toast.error("Station not found");
    this.navigateToStations();
  }

  validateBroadcaster(configName: string) {
    if (!this.stationId) {
      this.uiService.toast.error("Please save this station to validate the broadcaster");
      return;
    }

    this.zixi = {
      broadcaster: { loading: true },
      modalTitle: `Zixi Broadcaster Setup - ${configName}`
    };
    
    this.apiService.get({
      method: "zixi/validateSetup",
      params: {
        stationId: this.stationId,
        zixiBroadcasterId: configName === "East" ? this.station.zixiBroadcasterEast : this.station.zixiBroadcasterWest
      },
      onSuccess: (r) => {
        this.zixi.broadcaster = r.result;
      }
    });
  }

  test() {
    this.zixi = undefined;
  }
}
