<div class="page-header" *ngIf="station">
  <h3 class="title">{{station.name || station.stationDefaultData?.callSign || "New Station"}}</h3> {{station.save}}
<div class="button-group">
  <button pButton label="Save" class="p-button-sm p-button-outlined" (click)="save()" *ngIf="canEdit"></button>
  <button pButton label="Cancel" class="p-button-sm p-button-outlined" (click)="navigateToStations()" *ngIf="!stationId"></button>
  <button pButton label="Refresh" class="p-button-sm p-button-outlined" (click)="refresh(true)" *ngIf="stationId"></button>
</div>
</div>
<div class="page-body overflow-auto" *ngIf="station">
  <section>
    <div class="row">
      <div class="col-md-3">
        <label>Station Name</label>
        <input type="text" pInputText [(ngModel)]="station.name" maxlength="50" [disabled]="!canEdit" />
      </div>
      <div class="col-md-3">
        <label>Call Sign</label>
        <input type="text" pInputText [(ngModel)]="station.callSign" maxlength="10" [disabled]="!canEdit" />
      </div>
      <div class="col-md-3">
        <label>Station Description</label>
        <input type="text" pInputText [(ngModel)]="station.description" maxlength="100" [disabled]="!canEdit" />
      </div>
      <div class="col-md-3 active-cb">
        <p-checkbox [(ngModel)]="station.active" [binary]="true" [disabled]="!canEdit" label="Active"></p-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3" *ngIf="options.owners">
        <label>Owner</label>
        <p-dropdown [options]="options.owners" [(ngModel)]="station.ownerId" [disabled]="!canEdit"
                    optionLabel="name" optionValue="id" placeholder="-" [showClear]="true"></p-dropdown>
      </div>
      <div class="col-md-3" *ngIf="options.operators">
        <label>Operator</label>
        <p-dropdown [options]="options.operators" [(ngModel)]="station.operatorId" [disabled]="!canEdit"
                    optionLabel="name" optionValue="id" placeholder="Select" [showClear]="true"></p-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6" *ngIf="options.platforms">
        <label>Platforms</label>
        <p-multiSelect [options]="options.platforms" [(ngModel)]="station.platformIds" [disabled]="!canEdit"
                       optionLabel="name" optionValue="id" display="chip"></p-multiSelect>
      </div>
      <div class="col-md-6" *ngIf="options.dmas">
        <label>Designated Market Areas</label>
        <p-multiSelect [options]="options.dmas" [(ngModel)]="station.dmaIds" [disabled]="!canEdit"
                       optionLabel="name" optionValue="id" display="chip"></p-multiSelect>
      </div>
    </div>
  </section>
  <section>
    <h4>Schedule Source</h4>
    <div class="row">
      <div class="col-md-3" *ngIf="options.scheduleSources">
        <label>Data Source</label>
        <p-dropdown [options]="options.scheduleSources" [(ngModel)]="station.stationDataSourceId" [disabled]="!canEdit"
                    optionLabel="name" optionValue="stationDataSourceId" placeholder="-" [showClear]="true"></p-dropdown>
      </div>
      <div class="col-md-3" *ngIf="options.stations">
        <label>Station Mapping</label>
        <p-dropdown [options]="options.stations" [(ngModel)]="station.stationDefaultDataId" [disabled]="!canEdit" (onChange)="getExtraStationInfo()"
                    optionLabel="callSign" optionValue="stationDefaultDataId" placeholder="-" [showClear]="true">
          <ng-template let-o pTemplate="item">
            <div>{{o.callSign}}</div>
            <small>{{o.city}}, {{o.state}}</small>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="col-md-3">
        <label>City / State</label>
        <input type="text" pInputText [(ngModel)]="station._city" disabled />
      </div>
      <div class="col-md-3">
        <label>Time Zone</label>
        <input type="text" pInputText [(ngModel)]="station._timeZone" disabled />
      </div>
    </div>
  </section>
  <section>
    <h4>Zixi Broadcasters</h4>
    <div class="row">
      <div class="col-md-4">
        <label>East Broadcaster</label>
        <div class="d-flex" style="column-gap: 10px">
          <p-dropdown [options]="options.broadcasters.east" [(ngModel)]="station.zixiBroadcasterEast" [disabled]="!canEdit"
                      optionLabel="name" optionValue="name" placeholder="-" [showClear]="true" class="flex-grow"></p-dropdown>
          <button pButton label="Validate" class="p-button-sm p-button-outlined"
                  (click)="validateBroadcaster('East')" [disabled]="!station.zixiBroadcasterEast"></button>
        </div>
      </div>
      <div class="col-md-4">
        <label>West Broadcaster</label>
        <div class="d-flex" style="column-gap: 10px">
          <p-dropdown [options]="options.broadcasters.west" [(ngModel)]="station.zixiBroadcasterWest" [disabled]="!canEdit"
                      optionLabel="name" optionValue="name" placeholder="-" [showClear]="true" class="flex-grow"></p-dropdown>
          <button pButton label="Validate" class="p-button-sm p-button-outlined"
                  (click)="validateBroadcaster('West')" [disabled]="!station.zixiBroadcasterWest"></button>
        </div>
      </div>
    </div>
  </section>
  <section class="security" *ngIf="station.securityRoles">
    <h4>Station Roles</h4>
    <div *ngFor="let role of station.securityRoles" class="security">
      <div class="role">
        <h6>{{role.role.name}}</h6>
        <i class="pi pi pi-info-circle" pTooltip="{{role.role.description}}"></i>
      </div>
      <p-multiSelect [options]="options.users" [(ngModel)]="role.appUserIds" optionLabel="name" optionValue="id" display="chip" [disabled]="!canEdit"></p-multiSelect>
    </div>
  </section>
</div>

<p-dialog *ngIf="zixi" [(visible)]="zixi" [style]="{width:'800px'}" header="{{zixi.modalTitle}}">
  <ng-container *ngIf="!zixi.broadcaster.loading; else loading">
    <p-table [value]="zixi.broadcaster" [scrollable]="true" scrollHeight="flex">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="zixiObjectType">Object Type</th>
          <th pSortableColumn="name">Name</th>
          <th pSortableColumn="platform">Platform</th>
          <th pSortableColumn="exists">Exists</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-b>
        <tr>
          <td>{{b.zixiObjectType}}</td>
          <td>{{b.name}}</td>
          <td>{{b.platform}}</td>
          <td>
            <i class="pi" [ngClass]="b.exists ? 'pi-check' : 'pi-times text-danger'"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>

  <ng-template #loading>
    <div class="modal-loading">
      <i class="pi pi-spin pi-spinner"></i>
    </div>
  </ng-template>
</p-dialog>
